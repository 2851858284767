import React from "react"

import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import "./header.css"

const CaseStudySection = () => {
  const { heroImage } = useStaticQuery(
    graphql`
      {
        heroImage: file(
          relativePath: { eq: "new-images/quandary-casestudy-section-3.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              layout: CONSTRAINED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  const heroImageData = getImage(heroImage)

  // Use like this:
  const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <BackgroundImage
        Tag="div"
        {...bgImage}
        // fluid={imageData}
        className="hero-banner-case hero-overlay  relative"
        preserveStackingContext
        // style={{ backgroundPosition: "center" }}
        style={{ backgroundPosition: "70% 50%" }}
      >
        <div className="hero-overlay" />
        <div className="container">
          <div className="row" style={{ maxWidth: 1296, margin: "0 auto" }}>
            <div className="col-lg-12 text-center">
              <h2 className=" casestudysection-title text-white font-weight-medium mt-0 mb-4">
                Our Case Studies
              </h2>
            </div>

            {/* <div className="ml-lg-4 col-lg-7 mr-auto col-md-6 pt-sm-3 pt-sm-3 pr-sm-5 pl-sm-5"> */}
            {/* <div className="col-lg-7 mr-auto col-md-6 pt-sm-3 pt-sm-3"> */}

            <div className="col-lg-7 mr-auto col-md-6 pt-sm-3 pt-sm-3 md:px-2 px-0">
              <div className="pb-xs-0" style={{ padding: "2rem 0 0" }}>
                {/* <h5 className="text-light-blue text-uppercase font-weight-bold mt-0 mb-4">
                  WORKATO SOLUTIONS
                </h5> */}
                {/* <p
                  className="h5 text-light-blue text-uppercase font-weight-bold m-0 mb-5"
                  style={{ fontFamily: "Futura,sans-serif" }}
                >
                  Our Case Studies
                </p> */}

                <h3 className="text-center text-md-left text-white font-weight-medium mt-0 mb-4">
                  Leading Port Service Management Company Sees Processing Time
                  Drop
                  <br /> From 7 - 10 Days to 24 Hours
                </h3>
                {/* <p>
                  Connect workflows, processes, and applications with Workato’s
                  drag and drop interface. (No-Code Required.)
                </p> */}
                <div className="mt-4 text-center text-md-left">
                  <Link
                    aria-label="link"
                    className="btn btn-casestudy mr-2 mt-4 text-center"
                    to="/case-study/leading-port-service-management-company-at-the-port-of-savannah-continuously-optimizes/"
                  >
                    Learn More
                    {/* <i className="ml-2 item-arrow" aria-hidden="true"></i> */}
                  </Link>
                </div>
              </div>
            </div>

            <div
              className="col-lg-6 col-md-6 p-sm-5 d-none"
              style={{ position: "relative" }}
            ></div>
          </div>
        </div>
      </BackgroundImage>
    </>
  )
}

export default CaseStudySection
