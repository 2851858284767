import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const UnlockEnterprise = () => {
  return (
    <section
      id="it-consulting"
      className="it-consulting"
      style={{ backgroundColor: "whitesmoke", padding: "1.5rem 0" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-9 text-center mx-auto">
            {/* <h2 className="my-3 font-weight-medium">
              Unlock Enterprise Solutions
            </h2>
            <p className="text-left-mobile">
              Your team craves efficiency. The lean applications we design
              streamline everything. It's time to smash through bottlenecks and
              discover how productive your organization can be!
            </p> */}
            <h2 className="my-3">Our Technology Partners</h2>
          </div>
        </div>
        <div className="row px-5 mx-auto py-0 py-md-5">
          <div className="col-lg-3 col-sm-6 text-center mb-md-0">
            <div
              className="img-wrapper"
              style={{
                height: "5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link to="/partners/quickbase/">
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid rounded"
                  src="https://cdn.sanity.io/images/55lcecww/production/5dcc11dfa83272784d3b4a9d61a4a59062d2b40c-1788x292.png?w=600"
                  alt="Quickbase Logo"
                  layout="constrained"
                  style={{ width: "95%" }}
                  loading="lazy"
                  fetchpriority="low"
                />
              </Link>
            </div>
            {/* <h3>Quickbase</h3> */}
            {/* <p className="my-4" style={{ minHeight: "0rem" }}>
              As Quickbase Elite Partners, we use low code to help you conquer
              everyday challenges with custom applications that deploy rapidly.
            </p> */}
            {/* <br />
            <Link
              className="btn btn-primary btn-arrow"
              to="/partners/quickbase/"
            >
              Learn More
            </Link> */}
          </div>

          <div className="col-lg-3 col-sm-6 text-center mb-md-0">
            <div
              className="img-wrapper"
              style={{
                height: "5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link to="/partners/workato/">
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid rounded"
                  src="https://cdn.sanity.io/images/55lcecww/production/549f674c0eeae5959e36248b5337e51c4e72d927-900x534.png?w=600"
                  alt="Workato Logo"
                  layout="constrained"
                  style={{ width: "45%" }}
                  loading="lazy"
                  fetchpriority="low"
                />
              </Link>
            </div>
            {/* <h3>Workato</h3> */}
            {/* <p className="my-4" style={{ minHeight: "0rem" }}>
              We integrate and automate workflows with Workato, reducing errors
              and giving you more time to focus on your business.
            </p> */}
            {/* <br />
            <Link
              className="btn btn-primary btn-arrow"
              to="/partners/workato/"
            >
              Learn More
            </Link> */}
          </div>

          <div className="col-lg-3 col-sm-6 text-center mb-md-0">
            <div
              className="img-wrapper"
              style={{
                height: "5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link to="/partners/pipefy/">
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid rounded"
                  src="https://cdn.sanity.io/images/55lcecww/production/f5656afd01bfd93222721a81b219743d7a59d393-187x133.png?w=600"
                  alt="Pipefy Logo"
                  layout="constrained"
                  style={{ width: "65%" }}
                  loading="lazy"
                  fetchpriority="low"
                />
              </Link>
            </div>
            {/* <h3>Pipefy</h3> */}
            {/* <p className="my-4" style={{ minHeight: "0rem" }}>
              We use Pipefy to integrate business processes and workflows so you
              can scale better and reduce your back-office overhead.
            </p> */}
            {/* <br />
            <Link className="btn btn-primary btn-arrow" to="/partners/pipefy/">
              Learn More
            </Link> */}
          </div>
          <div className="col-lg-3 col-sm-6 text-center mb-md-0">
            <div
              className="img-wrapper"
              style={{
                height: "5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a href="/partners/trackvia/" rel="noopener noreferrer">
                <StaticImage
                  placeholder="blurred"
                  className="img-fluid rounded"
                  src="https://cdn.sanity.io/images/55lcecww/production/c57ec48f13deed7062e5d498232283927e40a517-387x113.png"
                  alt="TrackVia Logo"
                  layout="constrained"
                  style={{ width: "100%" }}
                  loading="lazy"
                  fetchpriority="low"
                />
              </a>
            </div>
            {/* <h3>Trackvia</h3> */}
            {/* <p className="my-4" style={{ minHeight: "0rem" }}>
              Smash bottlenecks and rapidly scale your business beyond what you
              thought possible with a range of out-of-the-box solutions.
            </p> */}
            {/* <br />
            <a
              className="btn btn-primary btn-arrow"
              href="https://trackvia.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default UnlockEnterprise
